






import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import PowerGroupInvoicesTable from "@/components/PowerGroupInvoicesTable.vue"

const PowerAccounts = namespace("PowerAccounts");

@Component({
    components: {
        WizardBaseStep,
        PowerGroupInvoicesTable
    }
})
export default class WizardStepPowerGroupInvoices extends Vue {

    @PowerAccounts.Mutation
    private setMpanSelectionDisabled!: (disabled: boolean) => void;

    @PowerAccounts.Mutation
    private setAccountSelectionDisabled!: (disabled: boolean) => void;


    mounted(): void {
        this.setMpanSelectionDisabled(true);
        this.setAccountSelectionDisabled(true);
    }

    beforeDestroy(): void {
        this.setAccountSelectionDisabled(false);
        this.setMpanSelectionDisabled(false);
    }
}
