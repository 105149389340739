





































































import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";
import moment from 'moment';
import VueMonthlyPicker from 'vue-monthly-picker';
import { namespace } from 'vuex-class';
import { DateValidation } from "@/utilities/DateValidation";
import ToastService from '@/services/toastService';
import { GtagHelper } from '../../../../utilities/GtagHelper';

const Invoices = namespace("Invoices")
const GasAccounts = namespace("GasAccounts");
const Auth = namespace("Auth");
const Products = namespace("Products");

@Component({
    components: {
        WizardBaseStep,
        VueMonthlyPicker
    }
})
export default class WizardStepBulkInvoicesForAccount extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    private selectedCustomDate = false;
    private processing = false;
    private maxProgress = 20;
    private counter = 0;
    private interval: (number | null) = null;
    private StartMonth = '';
    private EndMonth = '';
    private startMonthErrorMessage: string | null = null;
    private endMonthErrorMessage: string | null = null;
    private errorMessage: string | null = null;
    

    private validator = new DateValidation();

    private maxDate = moment();
    private monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    private alignment = 'center';

    private GtagHelper = new GtagHelper();

    setChooseCustomDateVarient() {
        if (this.selectedCustomDate) {
            return 'sefe-blue';
        }
        return 'outline-gazprom-blue';
    }

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @GasAccounts.Mutation
    private setMprnSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAgreementSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAccountSelectionDisabled!: (disabled: boolean) => void;

    @Products.Mutation
    private setProductSelectionDisabled!: (disabled: boolean) => void;

    @Auth.Getter
    private getUserId!: string;

    @Invoices.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    get selectedAccount() {
        return this.getSelectedAccount;
    }

    @Invoices.Action
    getLatestMonthInvoicesForAccount!: (params: { accountNum: string, userId : string }) => Promise<void>;

    @Invoices.Action
    getLatestYearInvoicesForAccount!: (params: { accountNum: string, userId: string }) => Promise<void>;

    @Invoices.Action
    getCustomRangeInvoicesForAccount!: (params: { accountNum: string, userId: string, fromMonth: string, toMonth: string }) => Promise<void>;

    private onChooseCustomDate() {
        this.selectedCustomDate = true;
    }

     

    async onInputDateStartMonth(date: any) {
        if (date !== null && date !== '') {
            this.validator.reset();
            this.errorMessagesReset();
            this.validator.setStartMonth = date.format('YYYY/MM/DD');
            const isValid = this.validator.validate();
            if (!isValid) {
                this.startMonthErrorMessage = this.validator.getStartMonthErrorMessage;
                this.errorMessage = this.validator.getErrorMessage;
                return;
            }
        } else {
            this.validator.setStartMonth = null;
        }

        this.validator.reset();
        this.errorMessagesReset();
        return;
    }

    async onInputDateEndMonth(date: any) {
        if (date !== null && date !== '') {
            this.validator.reset();
            this.errorMessagesReset();
            this.validator.setEndMonth = date.endOf('month').format('YYYY/MM/DD');
            const isValid = this.validator.validate();
            if (!isValid) {
                this.endMonthErrorMessage = this.validator.getEndMonthErrorMessage;
                this.errorMessage = this.validator.getErrorMessage;
                return;
            }
        } else {
            this.validator.setEndMonth = null;
        }

        this.validator.reset();
        this.errorMessagesReset();
        return;
    }

    private async onDownloadLatestMonth() {
        this.startInterval();
        try {
            await this.getLatestMonthInvoicesForAccount({ accountNum: this.selectedAccount, userId: this.getUserId });
            this.fileProcess();
            this.downloadSuccess();

            this.GtagHelper.Event("LumiDownloadBulkInvoicesLatestMonth", this.getUserId);
        } catch (error) {
            console.error(error);
        } finally {
            this.processing = false;
            this.clearInterval();
        }
    }

    private async onDownloadLatestYear() {
        this.startInterval();
        try {
            await this.getLatestYearInvoicesForAccount({ accountNum: this.selectedAccount, userId: this.getUserId });
            this.fileProcess();
            this.downloadSuccess();

            this.GtagHelper.Event("LumiDownloadBulkInvoicesLatestYear", this.getUserId);
        } catch (error) {
            console.error(error);
        } finally {
            this.processing = false;
            this.clearInterval();
        }
    }

    private async onDownloadCustom() {
        this.validator.reset();
        this.errorMessagesReset();
        this.validator.setDayDiff = false;
        const isValid = await this.validator.validate();
        if (!isValid) {
            this.startMonthErrorMessage = this.validator.getStartMonthErrorMessage;
            this.endMonthErrorMessage = this.validator.getEndMonthErrorMessage;
            this.errorMessage = this.validator.getErrorMessage;
            return;
        } else {
            this.startInterval();
            try {
                await this.getCustomRangeInvoicesForAccount({ accountNum: this.selectedAccount,userId: this.getUserId, fromMonth: this.validator.getStartMonth as string, toMonth: this.validator.getEndMonth as string });
                this.fileProcess();
                this.downloadSuccess();

                this.GtagHelper.Event("LumiDownloadBulkInvoicesCustomDates", this.getUserId);
            } catch (error) {
                console.error(error);
            } finally {
                this.processing = false;
                this.clearInterval();
            }
        }
    }

    private startInterval() {            
        this.processing = true;
        this.setAccountSelectionDisabled(true);
        this.setProductSelectionDisabled(true);
        this.$emit('invoiceprocessingchange', this.processing);
        this.interval = setInterval(() => {
            if (this.processing && this.counter < 20) {
                this.counter += 1;
            }
        }, 700);
    }

    private clearInterval() {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = null;
            this.counter = 0;                           
        }
        this.setAccountSelectionDisabled(false);
        this.setProductSelectionDisabled(false);
        this.$emit('invoiceprocessingchange', this.processing);
    }

    private fileProcess() {
        this.processing = false;
        this.clearInterval();
        var fileName = this.getDownloadFileData.fileName;
        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.getDownloadFileData.data) as Blob, fileName as string);
        } else {
            var fileURL = window.URL.createObjectURL((this.getDownloadFileData.data) as Blob);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }

    private downloadSuccess() {
        const SuccessMessageDefaults = ToastService.SuccessMessageDefaults();
        SuccessMessageDefaults.noAutoHide = true;
        SuccessMessageDefaults.variant = '#cfdf9a';
        SuccessMessageDefaults.bodyClass = 'copy-success-message';
        SuccessMessageDefaults.headerClass = 'copy-success-message';
        SuccessMessageDefaults.noCloseButton = false;
        SuccessMessageDefaults.title = 'Download Completed';
        this.$bvToast.toast("You can find your invoices in your download folder.", SuccessMessageDefaults);
    }

    private errorMessagesReset(): void {
        this.validator.reset();
        this.startMonthErrorMessage = null;
        this.endMonthErrorMessage = null;
        this.errorMessage = null;
    }

    beforeDestroy() {
        this.clearInterval();
        this.setAgreementSelectionDisabled(false);
        this.setMprnSelectionDisabled(false);
    }

    mounted(): void {
        this.setAgreementSelectionDisabled(true);
        this.setMprnSelectionDisabled(true);
    }
}
