






import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import InvoicesPowerTable from "@/components/InvoicesPowerTable.vue"
@Component({
    components: {
        WizardBaseStep,
        InvoicesPowerTable
    }
})
export default class WizardStepAccountInvoices extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
}
