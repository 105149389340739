


















import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"

@Component({
    components: {
        WizardBaseStep
    }
})
export default class WizardStepInvoicingPower extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Array, default: [] }) readonly next_step_id!: string[];
    private selectedOption = 0;
    private visible = true;

    onSelectedOptionChanged(value: any) {
        this.selectedOption = value;
        this.$emit("selectedInvoicingOption", this.selectedOption);
    }

    setVariant(value) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

}
