











































































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IPowerGroupInvoiceSummary } from "@/interfaces/powerGroupInvoiceSummary";
import moment from 'moment';

const Auth = namespace("Auth");
const PowerAccounts = namespace("PowerAccounts");
const PowerGroupInvoices = namespace("PowerGroupInvoices");

import { GtagHelper } from '../utilities/GtagHelper';

@Component
export default class PowerGroupInvoicesTable extends Vue {

    public unwatch: any;
    private loading = true;
    private currentPage = 1;
    private perPage = 10;
    private progressBarHidden = false;

    private GtagHelper = new GtagHelper();

    public invoiceFields = [
        {
            key: 'TaxPointDate',
            label: 'Date issued',
            formatter: this.formatDate
        },
        {
            key: 'GroupInvoiceNumber',
            label: 'Group Summary Number'
        },
        {
            key: 'Total',
            label: 'Amount',
            formatter: this.formatAmount
        },
        {
            key: 'GroupInvoiceDownloadLink',
            label: 'Group Summary'
        },
        {
            key: 'GroupSummaryDownloadLink',
            label: 'Group Statement'
        },
        {
            key: 'GroupStatementDownloadLink',
            label: 'Detailed Group Statement'
        }
        ];

    get groupInvoicesCount() {
        if (this.groupInvoices == null) { return 0; }
        return this.groupInvoices.length;
    }

    @Auth.Getter
    private getUserId!: string;

    @PowerAccounts.Getter
    private getSelectedGroupAccount!: string;

    @PowerGroupInvoices.Getter
    private groupInvoices!: IPowerGroupInvoiceSummary[];

    @PowerGroupInvoices.Action
    private getGroupInvoiceSummary!: (params: { userId: string, groupAccountNumber: string }) => Promise<void>

    @PowerGroupInvoices.Action
    private downloadGroupInvoice!: (params: { userId: string, groupAccountNumber: string, groupInvoiceNumber: string }) => Promise<void>

    @PowerGroupInvoices.Action
    private downloadGroupSummary!: (params: { userId: string, groupAccountNumber: string, groupInvoiceNumber: string }) => Promise<void>

    @PowerGroupInvoices.Action
    private downloadGroupStatement!: (params: { userId: string, groupAccountNumber: string, groupInvoiceNumber: string }) => Promise<void>

    @PowerGroupInvoices.Getter
    private getFileForDownload!: { data: Blob | null, fileName: string | null };

    private async onDownloadGroupInvoiceClick(groupInvoiceNumber) {
        const key = `GroupInvoice-${groupInvoiceNumber}`;

        this.onDownloadClick(groupInvoiceNumber, key, this.downloadGroupInvoice);
    }

    private async onDownloadGroupSummaryClick(groupInvoiceNumber) {
        const key = `GroupSummary-${groupInvoiceNumber}`;

        this.onDownloadClick(groupInvoiceNumber, key, this.downloadGroupSummary);
    }

    private async onDownloadGroupStatementClick(groupInvoiceNumber) {
        const key = `GroupStatement-${groupInvoiceNumber}`;

        this.onDownloadClick(groupInvoiceNumber, key, this.downloadGroupStatement);
    }

    private async onDownloadClick(groupInvoiceNumber: string, key: string, callback: (params: { userId: string, groupAccountNumber: string, groupInvoiceNumber: string }) => Promise<void>) {
        this.$refs[key]!['$el'].style.display = 'flex';

        const userId = this.getUserId;
        const groupAccountNumber = this.getSelectedGroupAccount;

        try {
            await callback({ userId, groupAccountNumber, groupInvoiceNumber });
            this.fileProcess();
            this.$refs[key]!['$el'].style.display = 'none';
        } catch (e) {
            console.error(e);
            this.$refs[key]!['$el'].style.display = 'none';
        }
    }

    private fileProcess() {

        if (this.getFileForDownload == null) {
            return null;
        }

        var fileName = this.getFileForDownload.fileName;
        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.getFileForDownload.data) as Blob, fileName as string);
          


        } else {
            var fileURL = window.URL.createObjectURL((this.getFileForDownload.data) as Blob);
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }

        this.GtagHelper.Event("LumiDownloadPowerInvoice", this.getUserId);
    }

    created() {
        try {
            this.unwatch = this.$store.watch(
                (state, getters) => getters["PowerAccounts/getSelectedGroupAccount"],
                (newValue, oldValue) => {
                    if (newValue === null || newValue === oldValue) {
                        return;
                    }

                    this.loading = true;
                    this.currentPage = 1;

                    const userId = this.getUserId;
                    const groupAccount = this.getSelectedGroupAccount;

                    this.getGroupInvoiceSummary({ userId: userId, groupAccountNumber: groupAccount }).then(() => {
                        this.loading = false;
                    }).catch(reason => {
                        this.loading = false;
                        console.error(reason);
                    });
                }
                , { immediate: true });
        } catch (e) {
            this.loading = false;
            console.error(e);
        }
    }

    beforeDestroy() {
        this.unwatch();
    }

    public formatAmount(value: number): string {
        if (value === null) {
            return "";
        }

        var formattedAmount = "£" + this.$options?.filters?.toCurrency(value).replace("-", "");

        if (value < 0) {
            formattedAmount = "-" + formattedAmount;
        }

        return formattedAmount;
    }     

    public formatDate(value: string): string {
        if (value === null) {
            return "";
        }

        return moment(value).format('DD/MM/YYYY');
    }

    public invoiceUnavailable(invoiceDate: string): boolean {

        const result = moment(invoiceDate).isBefore('01 Jan 2013');

        return result;
    }
}
