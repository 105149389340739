














import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"

import { namespace } from 'vuex-class';
const Auth = namespace("Auth");
const GasAccounts = namespace("GasAccounts");

@Component({
    components: {
        WizardBaseStep
    }
})
export default class WizardStepLatestInvoices extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Array, default: [] }) readonly next_step_id!: string[];
    private selectedOption = 0;
    private moreThanOneAccount = false;
    private isAdmin = false;
    private watchGasUserAccountsCount: any;

    @GasAccounts.Getter
    private getUserAccountsCount!: number;

    @Auth.Getter
    private isGazpromAdmin!: boolean;

    setVariant(value) {
        if (this.selectedOption == value)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    onSelectedOptionChanged(value: any) {
        this.selectedOption = value;
        this.$emit("selectedInvoicingOption", value);
    }

    mounted() {
        this.moreThanOneAccount = this.getUserAccountsCount > 1;
        this.isAdmin = this.isGazpromAdmin;

        this.watchGasUserAccountsCount = this.$store.watch((state, getters) => getters["GasAccounts/getUserAccountsCount"], (newValue, oldValue) => {
            if (newValue === null || newValue === oldValue) {
                return;
            }
            this.moreThanOneAccount = this.getUserAccountsCount > 1;
            this.isAdmin = this.isGazpromAdmin;
        });
    }

    beforeDestroy(): void {
        this.watchGasUserAccountsCount();
    }

}
