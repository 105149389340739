







import { Component, Prop, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue"
import InvoicesTable from "@/components/InvoicesTable.vue"
@Component({
    components: {
        WizardBaseStep,
        InvoicesTable
    }
})
export default class WizardStepInvoicesForAgreement extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: Boolean, default: false }) readonly visible!: boolean;

}
