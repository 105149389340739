


























































import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";
import VueMonthlyPicker from 'vue-monthly-picker';
import moment from 'moment';
import { namespace } from 'vuex-class';
import { DateValidation } from "@/utilities/DateValidation";

const Invoices = namespace("Invoices")
const GasAccounts = namespace("GasAccounts");
const Auth = namespace("Auth");
const InvoicingWizard = namespace("InvoicingWizard");

@Component({
    components: {
        WizardBaseStep,
        VueMonthlyPicker
    }
})
export default class WizardStepInvoicingGas extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    @Prop({ type: String, default: '' }) readonly next_step_id!: string;

    private selectedOption = 0;
    private allowCustomDownload = false;
    private maxDate = moment();
    private monthLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    private alignment = 'center';
    private processing = false;
    private maxProgress = 20;
    private counter = 0;
    private interval: (number | null) = null;
    private startMonthErrorMessage: string | null = null;
    private endMonthErrorMessage: string | null = null;
    private errorMessage: string | null = null;
    private StartMonth = '';
    private EndMonth = '';
    private selectedCustomDate = false;
    private visible = true;
    private showLatestInvoicesSelectedIcon = false;
    private showCustomDatesSelectedIcon = false;
    private validator = new DateValidation();

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private getUserId!: string;

    @Invoices.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @Invoices.Action
    getCustomRangeInvoicesForAccount!: (params: { accountNum: string, userId: string, fromMonth: string, toMonth: string }) => Promise<void>;

    @InvoicingWizard.Action
    private resetJourneyNode!: () => Promise<void>;

    @InvoicingWizard.Action
    private initJourneyNode!: (option: number) => Promise<void>;

    get selectedAccount() {
        return this.getSelectedAccount;
    }

    get setLatestInvoiceVariant() {
        if (this.showLatestInvoicesSelectedIcon)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    get setCustomDatesVariant() {
        if (this.showCustomDatesSelectedIcon)
            return 'sefe-blue';
        else return 'outline-gazprom-blue';
    }

    private onChooseCustomDate() {
        this.selectedCustomDate = true;
        this.resetJourneyNode();
        this.selectedOption = 0;
        this.showLatestInvoicesSelectedIcon = false;
        this.showCustomDatesSelectedIcon = true;
    }

    onSelectedOptionChanged() {
        this.selectedOption = 1;
        this.$emit("selectedInvoicingOption", this.selectedOption);            
        this.selectedCustomDate = false;
        this.showLatestInvoicesSelectedIcon = true;
        this.showCustomDatesSelectedIcon = false;
    }

    async onInputDateStartMonth(date: any) {
        if (date !== null && date !== '') {
            this.validator.reset();
            this.errorMessagesReset();
            this.validator.setStartMonth = date.format('YYYY/MM/DD');
            const isValid = this.validator.validate();
            if (!isValid) {
                this.startMonthErrorMessage = this.validator.getStartMonthErrorMessage;
                this.errorMessage = this.validator.getErrorMessage;
                return;
            }
        } else {
            this.validator.setStartMonth = null;
        }

        this.validator.reset();
        this.errorMessagesReset();
        return;
    }

    async onInputDateEndMonth(date: any) {
        if (date !== null && date !== '') {
            this.validator.reset();
            this.errorMessagesReset();
            this.validator.setEndMonth = date.endOf('month').format('YYYY/MM/DD');
            const isValid = this.validator.validate();
            if (!isValid) {
                this.endMonthErrorMessage = this.validator.getEndMonthErrorMessage;
                this.errorMessage = this.validator.getErrorMessage;
                return;
            }
        } else {
            this.validator.setEndMonth = null;
        }

        this.validator.reset();
        this.errorMessagesReset();
        return;
    }

    private async onDownloadCustom() {
        this.validator.reset();
        this.errorMessagesReset();
        const isValid = await this.validator.validate();
        if (!isValid) {
            this.startMonthErrorMessage = this.validator.getStartMonthErrorMessage;
            this.endMonthErrorMessage = this.validator.getEndMonthErrorMessage;
            this.errorMessage = this.validator.getErrorMessage;
            return;
        } else {
            this.startInterval();
            try {
                await this.getCustomRangeInvoicesForAccount({ accountNum: this.selectedAccount, userId: this.getUserId, fromMonth: this.validator.getStartMonth as string, toMonth: this.validator.getEndMonth as string });
                this.fileProcess();
            } catch (error) {
                console.error(error);
            } finally {
                this.processing = false;
                this.clearInterval();
            }
        }
    }

    private startInterval() {
        this.processing = true;
        this.interval = setInterval(() => {
            if (this.processing && this.counter < 20) {
                this.counter += 1;
            }
        }, 700);
    }

    private clearInterval() {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = null;
            this.counter = 0;               
        }
    }

    private fileProcess() {
        this.processing = false;
        this.clearInterval();

        var fileName = this.getDownloadFileData.fileName;
        if (window.navigator && window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob((this.getDownloadFileData.data) as Blob, fileName as string);
        } else {
            var fileURL = window.URL.createObjectURL((this.getDownloadFileData.data) as Blob);
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName as string);
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }

    private errorMessagesReset(): void {
        this.validator.reset();
        this.startMonthErrorMessage = null;
        this.endMonthErrorMessage = null;
        this.errorMessage = null;
    }

    beforeDestroy() {
        this.clearInterval()
    }

}
