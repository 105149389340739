

































































import { Component, Watch, Vue } from "vue-property-decorator";
import BasePage from "@/components/pages/BasePage.vue";
import BaseWizard from "@/components/wizard/BaseWizard.vue"
import WizardStepInvoicingGas from "@/components/wizard/invoicing/gas/WizardStepInvoicingGas.vue"
import WizardStepInvoicingPower from "@/components/wizard/invoicing/power/WizardStepInvoicingPower.vue"
import WizardStepLatestInvoices from "@/components/wizard/invoicing/gas/WizardStepLatestInvoices.vue"
import WizardStepInvoicesForAgreement from "@/components/wizard/invoicing/gas/WizardStepInvoicesForAgreement.vue"
import WizardStepBulkInvoicesForAccount from "@/components/wizard/invoicing/gas/WizardStepBulkInvoicesForAccount.vue"
import WizardStepBulkInvoicesForAccounts from "@/components/wizard/invoicing/gas/WizardStepBulkInvoicesForAccounts.vue"
import WizardStepAccountInvoices from "@/components/wizard/invoicing/power/WizardStepAccountInvoices.vue"
import WizardStepPowerGroupInvoices from "@/components/wizard/invoicing/power/WizardStepPowerGroupInvoices.vue"
import HelpAndSupport from "@/components/HelpAndSupport.vue"

import { JourneyNode } from '@/utilities/JourneyNode';
import { namespace } from 'vuex-class';

const Products = namespace("Products");
const InvoicingWizard = namespace("InvoicingWizard");
const GasAccounts = namespace("GasAccounts");
const PowerAccounts = namespace("PowerAccounts");
const Auth = namespace("Auth");

@Component({
    components: {
        BaseWizard,
        BasePage,
        WizardStepInvoicingGas,
        WizardStepInvoicingPower,
        WizardStepLatestInvoices,
        WizardStepInvoicesForAgreement,
        WizardStepBulkInvoicesForAccounts,
        WizardStepBulkInvoicesForAccount,
        WizardStepAccountInvoices,
        WizardStepPowerGroupInvoices,
        HelpAndSupport
    },
})
export default class Invoicing extends Vue {
    private isExactActive = false;
    private watchProductSelection: any;
    private watchGasAccountSelection: any;
    private watchPowerAccountSelection: any;
    private componentKey = 0;
    private transitionName = 'fade';
    private onSingleAccountAndOnSingleSite = false;
    private moreThanOneAccount = true;
    private processing = false;
    private maxProgress = 20;
    private counter = 0;
    private interval: (number | null) = null;


    private next_step_gas_id = ["step-1", "step-4", "step-5"];
    private next_step_power_id = ["step-1", "step-2"];

    @Watch('$route.path', { immediate: true, deep: true })
    onUrlChange(to: string, from: string) {
        if (to == null || to == undefined) {
            this.transitionName = 'fade';
            return;
        }
        const toDepth = to.split('/').length
        const fromDepth = from?.split('/').length
        this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left';
    }

    @InvoicingWizard.Getter
    private getRootJourney!: JourneyNode | null;

    @InvoicingWizard.Action
    private resetJourneyNode!: () => Promise<void>;

    @InvoicingWizard.Action
    private initJourneyNode!: (option: number) => Promise<void>;

    @InvoicingWizard.Action
    private appendJourneyToJourneyNode!: (option: number) => Promise<void>;

    @Products.Getter
    private getSelectedProduct!: string;

    @GasAccounts.Getter
    private isSass!: boolean;

    @GasAccounts.Getter
    private getUserAccountsCount!: number;

    @Auth.Mutation
    private setNavDisabled!: (disabled: boolean) => void;

    public async onSelectedInvoicingOption(option: number) {
        await this.initJourneyNode(option);
    }

    public async onSelectedLatestInvoicesOption(option: number) {
        await this.appendJourneyToJourneyNode(option);
    }

    public findJourney(option: number): boolean {
        let root = this.getRootJourney;
        if (root !== null) {
            while (root !== null) {
                if ((root as JourneyNode).Value == option) {
                    return true;
                }
                root = (root as JourneyNode).Next;
            }
        }
        return false;
    }

    public changeProcessStatus(data) {
        this.processing = data;
        this.setNavDisabled(data);
        if (this.processing) {
            this.interval = setInterval(() => {
                if (this.processing && this.counter < 20) {
                    this.counter += 1;
                }
            }, 700);
        } else {
            if (this.interval) {
                clearInterval(this.interval)
                this.interval = null;
                this.counter = 0;
            }
        }
    }

    updated() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
    }

    mounted() {
        this.isExactActive = typeof this.$refs.rv === 'undefined';
        this.resetJourneyNode();

        try {
    
            this.watchGasAccountSelection = this.$store.watch((state, getters) => getters["GasAccounts/getSelectedAccount"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.forceRerender();
            });

            this.watchPowerAccountSelection = this.$store.watch((state, getters) => getters["PowerAccounts/getSelectedGroupAccount"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.resetJourneyNode();
                this.forceRerender();
            });

            this.watchProductSelection = this.$store.watch((state, getters) => getters["Products/getSelectedProduct"], (newValue, oldValue) => {
                if (newValue === null || newValue === oldValue) {
                    return;
                }
                this.resetJourneyNode();
                this.forceRerender();
                this.onSingleAccountAndOnSingleSite = this.isSass;
            }, { immediate: true });

        } catch (e) {

            console.error(e);
        }

        this.onSingleAccountAndOnSingleSite = this.isSass;
        this.moreThanOneAccount = this.getUserAccountsCount > 1;
    }

    forceRerender() {
        this.componentKey += 1
    }

    beforeDestroy() {
        this.watchProductSelection();
        this.watchGasAccountSelection();
        this.watchPowerAccountSelection();
    }
}
