















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import WizardBaseStep from "@/components/wizard/WizardBaseStep.vue";
import { FileProcess } from "@/utilities/FileProcess";
import ToastService from '@/services/toastService';
import { GtagHelper } from '../../../../utilities/GtagHelper';

import moment from 'moment';
import { BFormDatepicker } from 'bootstrap-vue';

const GasAccounts = namespace("GasAccounts");
const Invoices = namespace("Invoices")
const Auth = namespace("Auth");
const Products = namespace("Products");

@Component({
    components: {
        WizardBaseStep,
        BFormDatepicker
    }
})
export default class WizardStepBulkInvoicesForAccounts extends Vue {
    @Prop({ type: String, default: '' }) readonly id!: string;
    private selectedCustomDate = false;
    private processing = false;
    private maxProgress = 20;
    private counter = 0;
    private interval: (number | null) = null;
    private StartDate = '';
    private EndDate = '';


    private now = new Date();
    private today = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
    private maxDate = new Date(this.today);

    private alignment = 'center';

    private GtagHelper = new GtagHelper();

    setChooseCustomDateVarient() {
        if (this.selectedCustomDate) {
            return 'sefe-blue';
        }
        return 'outline-gazprom-blue';
    }

    @GasAccounts.Mutation
    private setMprnSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAgreementSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Mutation
    private setAccountSelectionDisabled!: (disabled: boolean) => void;

    @Products.Mutation
    private setProductSelectionDisabled!: (disabled: boolean) => void;

    @GasAccounts.Getter
    private getSelectedAccount!: string;

    @Auth.Getter
    private getUserId!: string;

    @Invoices.Getter
    private getDownloadFileData!: { data: Blob | null, fileName: string | null };

    @Invoices.Action
    getCustomRangeInvoicesForAllAccounts!: (params: { userId: string, fromDate: string, toDate: string }) => Promise<void>;

    get selectedAccount() {
        return this.getSelectedAccount;
    }

    @Invoices.Action
    getInvoicesDownloadForAllAccounts!: (params: { userId: string }) => Promise<void>;

    private onChooseCustomDate() {
        this.selectedCustomDate = true;
    }

    private async onDownloadLatestInvoicesForAllAccounts() {

        if (this.selectedCustomDate == true) {
            this.selectedCustomDate = false;
        }

        this.$validator.validateAll().then((isValid => {
            if (!isValid) {
                this.processing = false;
                return;
            }

            this.startInterval();

            try {
                this.getInvoicesDownloadForAllAccounts({ userId: this.getUserId}).then(() => {
                    this.processing = false;

                    var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
                    if (success) {
                        this.clearInterval();
                        this.downloadSuccess();

                        this.GtagHelper.Event("LumiDownloadBulkInvoicesAllAccount", this.getUserId);
                    }
                }, (error) => {
                    this.processing = false;
                    this.clearInterval();
                    console.error(error.response.data);
                })

            } catch (error) {
                console.error(error);
                this.clearInterval();
                console.log(error.response.date);
            }
        }));
    }

    private async onDownloadCustom() {
        this.$validator.validateAll().then((isValid => {
            if (!isValid) {
                this.processing = false;
                return;
            }
            this.getInvoicesDownloadForAllAccounts
            this.startInterval();

            try {
                this.getCustomRangeInvoicesForAllAccounts({ userId: this.getUserId, fromDate: moment(String(this.StartDate)).format('YYYY/MM/DD') as string, toDate: moment(String(this.EndDate)).format('YYYY/MM/DD') as string }).then(() => {

                    var success = this.FileProcess.downlaodFile(this.getDownloadFileData.data, this.getDownloadFileData.fileName);
                    if (success) {
                        this.clearInterval();
                        this.downloadSuccess();

                        this.GtagHelper.Event("LumiDownloadBulkInvoicesAllAccountsCustomDates", this.getUserId)
                    }
                }, (error) => {
                    this.clearInterval();
                    console.error(error.response.data);
                })

            } catch (error) {
                console.error(error);
                this.clearInterval();
                console.log(error.response.date);
            }
        }));
    }

    private startInterval() {
        this.processing = true;
        this.setProductSelectionDisabled(true);
        this.$emit('invoiceprocessingchange', this.processing);
        this.interval = setInterval(() => {
            if (this.processing && this.counter < 20) {
                this.counter += 1;
            }
        }, 700);
    }

    private clearInterval() {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = null;
            this.counter = 0;
        }
        this.processing = false;
        this.setProductSelectionDisabled(false);
        this.$emit('invoiceprocessingchange', this.processing);
    }

    private FileProcess = new FileProcess();

    private downloadSuccess() {
        const SuccessMessageDefaults = ToastService.SuccessMessageDefaults();
        SuccessMessageDefaults.noAutoHide = true;
        SuccessMessageDefaults.variant = '#cfdf9a';
        SuccessMessageDefaults.bodyClass = 'copy-success-message';
        SuccessMessageDefaults.headerClass = 'copy-success-message';
        SuccessMessageDefaults.noCloseButton = false;
        SuccessMessageDefaults.title = 'Download Completed';
        this.$bvToast.toast("You can find your invoices in your download folder.", SuccessMessageDefaults);
    }

    beforeMount() {
        const dict = {
            custom: {
                customInvoiceReportDateFrom: {
                    required: 'Report date from is required'
                },
                customInvoiceReportDateTo: {
                    required: 'Report date to is required'
                }

            }
        };

        this.$validator.localize('en', dict);
    }

    beforeDestroy() {
        this.clearInterval();
        this.setAccountSelectionDisabled(false);
        this.setAgreementSelectionDisabled(false);
        this.setMprnSelectionDisabled(false);
    }

    mounted(): void {
        this.setAccountSelectionDisabled(true);
        this.setAgreementSelectionDisabled(true);
        this.setMprnSelectionDisabled(true);
    }
}
